import {getLocale} from '@wix/wix-events-commons-statics/dist/locale'
import {MemberPageState} from '../../members-page/types/state'
import {CommonState} from '../types/state'

const MAX_FULL_WIDTH_MARGIN = 100

export const getFullLocale = (state: CommonState) => getLocale(state.environment.locale)

export const getLanguage = (state: CommonState) => state.environment.language

export const getTimezone = (state: MemberPageState) => state.environment.timezone

export const isMobile = (state: CommonState) => state.environment.mobile

export const isEditor = (state: CommonState) => state.environment.editor

export const isSite = (state: CommonState) => state.environment.site

export const isPreview = (state: MemberPageState) => state.environment.preview

export const isFullWidth = (state: CommonState, dimensions: {width: number; left: number}) =>
  isSite(state) ? String(dimensions.width) === '' : dimensions.left < MAX_FULL_WIDTH_MARGIN
